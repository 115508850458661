
// Typography
$base-font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif; // $helvetica;
$heading-font-family: "Raleway", "Helvetica Neue", "Helvetica", "Arial", sans-serif; // $base-font-family;

// Font Sizes
$base-font-size: $em-base; // 16px

// Adjust modular scale ratio
$modular-scale-ratio: $minor-third;

// Line height
$base-line-height: 1.8;
$heading-line-height: 1.8;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * $em-base;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$dark-gray: #222;
$dark-gray-translucent: rgba($dark-gray, 0.6);
$medium-gray: #7f7f7f;
$light-gray: #ddd;
$action: #780113;
$background-grey: #373535;
$white: #fff;

// Font Colors
$base-background-color: #fff;
$base-font-color: $dark-gray;
$action-color: $action;
$highlight-color: tint($action-color, 33%);

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Screen sizes
$mobile: 640px;
$tablet: 960px;

$max-width: rem(1200);
$medium-screen: $mobile;
$large-screen: $tablet;