@import "../neat/neat-helpers"; // or "../neat/neat-helpers" when not in Rails

// Neat Overrides
// $column: 90px;
// $gutter: 30px;
// $grid-columns: 12;
// $max-width: em(1088);

// Neat Breakpoints
$medium-screen: em(640);
$large-screen: em(860);

$medium-screen-up: new-breakpoint(min-width $medium-screen 4);
$large-screen-up: new-breakpoint(min-width $large-screen 8);
